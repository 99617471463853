<template>
	<b-card>
		<ValidationObserver>
			<form @submit.prevent="saveItem">
				<b-row>
					<b-col cols="12" md="5">
						<h3>Admin Info</h3>
						<div class="form-group">
							<label>Name*</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text" 
									class="form-control" 
									:class="classes"
									v-model="formUser.name"
									:state="errors.length > 0 ? false:null"
								>
								<span
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</span>
							</validation-provider>
						</div>
						<div class="form-group">
							<label>Phone</label>
							<input 
								type="text" 
								class="form-control" 
								v-model="formUser.phone"
							>
						</div>
						<div class="form-group">
							<label>Email*</label>
							<validation-provider
								name="email"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="email" 
									class="form-control" 
									:class="classes"
									v-model="formUser.email"
									:state="errors.length > 0 ? false:null"
									placeholder="admin@hipajak.id"
								>
								<span
									v-for="(validation, index) in validations.email"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</span>
							</validation-provider>
						</div>
						<div class="form-group">
							<label>Password</label>
								<input 
									type="password" 
									class="form-control"
									v-model="formUser.password"
									placeholder=".........."
								>
						</div>
						<div class="form-group">
							<label>Password Confirmation</label>
							<input 
								type="password" 
								class="form-control" 
								v-model="formUser.password_confirmation"
								placeholder=".........."
							>
						</div>
						
					</b-col>
					<b-col cols="12" md="7">
						<validation-provider
							name="permissions"
							rules="required"
						>
							<h3>Permission Info</h3>
							<span
								v-for="(validation, index) in validations.permissions"
								:key="`errorName${index}`"
								class="text-danger"
							>
								Wajib minimal pilih 1 atau pilih semua</span>
							<div
								class="row mt-1"
							>
								<div class="col-12">
									<h6><strong>All Features :</strong></h6>
								</div>
								<div class="col-md-6 mb-0">
									<div class="form-group">
										<div class="custom-control custom-checkbox">
											<input 
												type="checkbox" 
												class="custom-control-input"
												name="parentCheckbox"
												id="parentCheck"
												@change="checkAll"
											>
											<label 
												class="custom-control-label text-capitalize"
												for="parentCheck"
											>
												All Features
											</label>
										</div>
									</div>
								</div>
							</div>
							<div
								v-for="permissionItem, key in permissions"
								:key="key"
								class="row mt-1"
							>
								<div class="col-12">
									<h6><strong>{{ key }} :</strong></h6>
								</div>
								<div
									v-for="permission in permissionItem"
									:key="permission.id"
									class="col-md-6 mb-0"
								>
									<div class="form-group">
										<div class="custom-control custom-checkbox">
											<input 
												name="childCheckbox"
												type="checkbox" 
												:id="'permission_checkbox_' + permission.id"
												:value="permission.id"
												v-model="formUser.permission_ids"
												class="custom-control-input checkbox"
											>
											<label 
												class="custom-control-label text-capitalize" :for="'permission_checkbox_' + permission.id"
											>
												{{ permission.name }}
											</label>
										</div>
									</div>
								</div>
							</div>
						</validation-provider>
					</b-col>
				</b-row>
				
				<b-col cols="12" class="p-0 mt-2">
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
					>
						Save
					</button>
					&nbsp;
					&nbsp;
					<a
						@click="handleBackButton()"
						variant="secondary"
						class="btn waves-effect waves-float waves-light btn-secondary"
					>
						Cancel
					</a>
				</b-col>
			</form>
		</ValidationObserver>
	</b-card>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BRow, 
	BCol,
	BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BFormCheckbox,
		ValidationProvider,
		ValidationObserver,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			formUser:{
				name: '',
				email: '',
				phone: '',
				password: '',
				password_confirmation: '',
				permissions: [],
			},
			permissions:[],
			validations: '',
			checked: '',
		}
	},

	mounted() {
		this.loadItem()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		checkAll() {
			//select all features checkbox
			this.formUser.permission_ids = []
			var all_features = document.getElementById("parentCheck");
			var checkbox = document.getElementsByClassName("checkbox");
			for(var i=0; i<checkbox.length; i++) {
				checkbox[i].checked = all_features.checked;
				if(all_features.checked) {
					this.formUser.permission_ids.push(checkbox[i].value)
				} else {
					this.formUser.permission_ids = []
				}
			}
			var uniqueNames = this.getUnique(this.formUser.permission_ids);
			this.formUser.permission_ids = uniqueNames
			for(var i=0; i< checkbox.length; i++) {
				checkbox[i].addEventListener('change', function(e) {
					if(this.checked == false){
						all_features.checked = false;
					}
					if(document.querySelectorAll('.checkbox:checked').length == checkbox.length){
						all_features.checked = true;
					}
				});
			}
		},
		getUnique(array){
			var uniqueArray = [];
			
			// Loop through array values
			for(var value of array){
				if(uniqueArray.indexOf(value) === -1){
					uniqueArray.push(value);
				}
			}
			return uniqueArray;
		},
		async loadItem() {
			const params = this.$route.params.uuid
			await this.$http.get('admin/users/' + params)
				.then(response => {
					const userData = response.data.data
					this.formUser = userData
					console.log(this.formUser, 'ini user');
				})
		},
		loadPermission() {
			this.$http.get('admin/acl/permissions')
			.then(response => {
				const permissionData = response.data.data
				this.permissions = permissionData
				if(this.formUser.permission != null){

				}
			})
		},
		saveItem() {
			const params = this.$route.params.uuid
			this.formUser.permissions = this.formUser.permission_ids
			this.$http.patch('admin/users/' + params, this.formUser)
			.then(response => {
				this.loadItem()
				successNotification(this, 'Success', 'User admin sukses diedit!')
				this.$router.push({ name: 'admin-user' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama', this.validations.name.join(' '))
					errorNotification(this, 'Email', this.validations.email.join(' '))
					errorNotification(this, 'Password', this.validations.password.join(' '))
					errorNotification(this, 'Permission', this.validations.permissions.join(' '))
				}
			})
		}
	},

	created() {
		this.loadPermission()
	}

}
</script>

<style scoped>
	.form-check-inline {
		flex-wrap: wrap;
	}
	.custom-checkbox.custom-control {
		padding-left: 1.8rem;
		margin-right: 1.8rem;
		margin-bottom: 1rem;
	}
</style>